import { createAsyncThunk } from '@reduxjs/toolkit';
import {
    deleteApi,
    getApi,
    patchApi,
    postApi,
} from '../../../scripts/api-services';
import {
    ADD_CART_ITEM_ENDPOINT,
    CLEAR_CART_ENDPOINT,
    DELETE_CART_ITEM_ENDPOINT,
    GET_CART_ENDPOINT,
    GET_CART_ITEM_COUNT_ENDPOINT,
    UPDATE_CART_QUANTITY_ENDPOINT,
    UPDATE_DELIVERY_TYPE,
    UPDATE_STORE_IN_CART,
} from '@/constants/endpoints/cartEndPoints';
import toast from 'react-hot-toast';
import { getCookie } from 'cookies-next';
import { setDeliveryType } from '../checkout-slice';
import { HOME_DELIVERY, STORE_PICKUP } from '@/constants/payment-constant';

export const getCartData: any = createAsyncThunk(
    'cart/getCartData',
    async (_, { dispatch }) => {
        try {
            const token = getCookie('accessToken');
            if (!token) {
                return;
            }
            const storeId = localStorage.getItem('location');
            const response = await postApi(GET_CART_ENDPOINT, {
                // it is not relevant
                storeId: storeId,
            });
            dispatch(setDeliveryType(response.data?.data[0]?.deliveryType));
            return response.data;
        } catch (error: any) {
            console.log('Error fetch cart API', error);
            // toast.error(error?.message[0]);
            return Promise.reject(error);
        }
    }
);

export const addCartItem: any = createAsyncThunk(
    'cart/addItemToCart',
    async (incomingData: any, { dispatch, getState }) => {
        const { notify = true, ...reqData } = incomingData;
        // let cartData = getState().cart_store;
        // let { cartId } = cartData || {};
        // if (!cartId) {
        //     await dispatch(getCartData());
        // }
        // cartData = getState().cart_store;
        // cartId = cartData?.cartId;

        try {
            const token = getCookie('accessToken');
            if (!token) {
                toast.error('Please login to add product in the cart');
                return;
            }
            const response = await postApi(ADD_CART_ITEM_ENDPOINT, reqData);

            // if (!cartId) {
            //     cartId = response?.data?.data?._id;
            // }
            // let updateDeliveryTypePayload = {
            //     cartId: cartId,
            //     deliveryType: HOME_DELIVERY,
            // };
            // const deliveryRes =  await dispatch(updateDeliveryType(updateDeliveryTypePayload));
            // const deliveryData = deliveryRes?.payload?.data?.data;
            // let storeId = deliveryData?.defaultStore?._id;

            // if (!storeId) {
            //     storeId = deliveryData?.multistore?.[0]?._id;
            // }

            // const cartInventoryByStoreRes = await dispatch(updateStoreIdInCart({ cartId, storeId }));

            if (notify) {
                toast.success('Product added to cart');
            }
            dispatch(getCartItemCount());
            dispatch(getCartData());

            return response;
        } catch (error: any) {
            console.log('Error add cart item API', error);
            toast.error(error?.message[0]);
            return Promise.reject(error);
        }
    }
);

export const updateCartItem: any = createAsyncThunk(
    'cart/updateCartItem',
    async (reqData: any, {dispatch, getState}) => {
        try {
            const response = await patchApi(
                UPDATE_CART_QUANTITY_ENDPOINT,
                reqData
            );

            const deliveryType = getState().checkout_store.deliveryType;
            let cartData = getState().cart_store;
            let { cartId } = cartData || {};
            if (!cartId) {
                await dispatch(getCartData());
            }
            cartData = getState().cart_store;
            cartId = cartData?.cartId;

            if (deliveryType === STORE_PICKUP) {
                let updateDeliveryTypePayload = {
                    cartId,
                    deliveryType: STORE_PICKUP,
                };
                await dispatch(updateDeliveryType(updateDeliveryTypePayload));
            }

            // if (deliveryType === HOME_DELIVERY) {
            //         let updateDeliveryTypePayload = {
            //             cartId: cartId,
            //             deliveryType: HOME_DELIVERY,
            //         };
            //         const deliveryRes = await dispatch(
            //             updateDeliveryType(updateDeliveryTypePayload)
            //         );
            //         const deliveryData = deliveryRes?.payload?.data?.data;
            //         let storeId = deliveryData?.defaultStore?._id;

            //         if (!storeId) {
            //             storeId = deliveryData?.multistore?.[0]?._id;
            //         }

            //         const cartInventoryByStoreRes = await dispatch(
            //             updateStoreIdInCart({ cartId, storeId })
            //         );
            // } else {
            //     const selectedStoreIdForPickup = getState().checkout_store.selectedStoreIdForPickup;
            //     if (selectedStoreIdForPickup) {
            //         const cartInventoryByStoreRes = await dispatch(
            //             updateStoreIdInCart({ cartId, storeId: selectedStoreIdForPickup })
            //         );
            //     }
            // }

            return response;
        } catch (error: any) {
            console.log('Error update cart item API', error);
            if (error?.statusCode === 400) {
                toast.error(error?.message?.toString());
            }
            return Promise.reject(error);
        }
    }
);

export const deleteCartItem: any = createAsyncThunk(
    'cart/deleteCartItem',
    async (reqData: any, {getState, dispatch}) => {
        try {
            let _id: string = reqData?._id;
            const response = await deleteApi(
                `${DELETE_CART_ITEM_ENDPOINT}${_id}`,
                { data: reqData }
            );

            const deliveryType = getState().checkout_store.deliveryType;
            let cartData = getState().cart_store;
            let { cartId } = cartData || {};
            if (!cartId) {
                await dispatch(getCartData());
            }
            cartData = getState().cart_store;
            cartId = cartData?.cartId;

            if (deliveryType === STORE_PICKUP) {
                let updateDeliveryTypePayload = {
                    cartId,
                    deliveryType: STORE_PICKUP,
                };
                await dispatch(updateDeliveryType(updateDeliveryTypePayload));
            }

            //  if (deliveryType === HOME_DELIVERY) {
            //      let updateDeliveryTypePayload = {
            //          cartId: cartId,
            //          deliveryType: HOME_DELIVERY,
            //      };
            //      const deliveryRes = await dispatch(
            //          updateDeliveryType(updateDeliveryTypePayload)
            //      );
            //      const deliveryData = deliveryRes?.payload?.data?.data;
            //      let storeId = deliveryData?.defaultStore?._id;

            //      if (!storeId) {
            //          storeId = deliveryData?.multistore?.[0]?._id;
            //      }

            //      const cartInventoryByStoreRes = await dispatch(
            //          updateStoreIdInCart({ cartId, storeId })
            //      );
            //  } else {
            //      const selectedStoreIdForPickup =
            //          getState().checkout_store.selectedStoreIdForPickup;
            //      if (selectedStoreIdForPickup) {
            //          const cartInventoryByStoreRes = await dispatch(
            //              updateStoreIdInCart({
            //                  cartId,
            //                  storeId: selectedStoreIdForPickup,
            //              })
            //          );
            //      }
            //  }

            return response;
        } catch (error: any) {
            console.log('Error delete cart item API', error);
            toast.error(error?.message[0]);
            return Promise.reject(error);
        }
    }
);

export const clearCart: any = createAsyncThunk('cart/clearCart', async () => {
    try {
        const response = await deleteApi(CLEAR_CART_ENDPOINT);
        return response;
    } catch (error: any) {
        console.log('Error delete cart item API', error);
        return Promise.reject(error);
    }
});

export const getCartItemCount: any = createAsyncThunk(
    'cart/getCartItemCount',
    async () => {
        try {
            const response = await getApi(GET_CART_ITEM_COUNT_ENDPOINT);
            return response;
        } catch (error: any) {
            console.log('Error delete cart item API', error);
            return Promise.reject(error);
        }
    }
);

export const updateDeliveryType: any = createAsyncThunk(
    'cart/updateDeliveryType',
    async (reqData: any, { dispatch }) => {
        try {
            const response = await patchApi(UPDATE_DELIVERY_TYPE, reqData);
            dispatch(getCartData());
            return response;
        } catch (error: any) {
            console.error('Error update delivery type API', error);
            if (error?.statusCode === 400) {
                toast.error(error?.message?.toString());
            }
            if (error?.statusCode === 404) {
                toast.error(error?.message?.[0]);
            }
            return Promise.reject(error);
        }
    }
);

export const updateStoreIdInCart: any = createAsyncThunk(
    'cart/updateStoreInCart',
    async (payload: { cartId: string; storeId: string }, { dispatch }) => {
        try {
            const { cartId, storeId } = payload;
            const reqData = { cartId, storeId };
            const response = await patchApi(UPDATE_STORE_IN_CART, reqData);
            dispatch(getCartData());
            return response?.data;
        } catch (error) {
            console.error(
                'There was an error in updating the store id in cart. The error is: ',
                error
            );
            toast.error(
                'There was an error in updating the selected store. Please try again.'
            );
        }
    }
);
